<template>
  <ValidationObserver ref="observe">
    <b-form-checkbox-group v-model="selectedIds">
      <div>
        <div v-if="contactList.length > 0">
          <b-row>
            <b-col cols="12">
              <b-card no-body class="card">
                <template
                  #header
                  style="display: flex; justify-content: space-between"
                >
                  <div>
                    <b-form-checkbox v-model="selectedIds" @change="selectAll"
                      >تحديد الكل
                    </b-form-checkbox>
                  </div>
                  <b-button variant="transparnt" class="p-0" @click="deleteCon">
                    <unicon fill="gray" height="18px" name="trash-alt">
                    </unicon>
                  </b-button>
                </template>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              v-for="contacts in contactListData"
              :key="contacts.id"
            >
              <b-card class="card" style="margin-top: -25px">
                <template #header>
                  <div>
                    <b-form-checkbox :value="contacts.id"> </b-form-checkbox>
                    {{ contacts.title }}
                  </div>
                  <div>
                    {{ contacts.senderName }}
                  </div>

                  <div>
                    {{ formDate(contacts.contactDate) }}
                    <b-button
                      variant="transparnt"
                      class="p-0"
                      @click="goDetails(contacts.id)"
                    >
                      <unicon fill="gray" height="20px" name="ellipsis-v">
                      </unicon>
                    </b-button>
                  </div>
                </template>
                <b-card-text>
                  <div class="d-flex">
                    <b-badge
                      class="p-25 rounded-circle h-100 mr-1"
                      variant="success"
                      v-b-tooltip.hover
                      title=" تم الرد"
                      v-if="contacts.response"
                    >
                      <unicon name="check" fill="#fff" />
                    </b-badge>
                    {{ contacts.content.substring(0, 30) }}
                  </div>
                </b-card-text>
              </b-card>
            </b-col>

            <b-col cols="12" class="d-flex justify-content-center">
              <ek-pagination
                :items="contactList"
                v-model="contactListData"
                :pageLength="5"
              />
            </b-col>
          </b-row>
        </div>
        <div v-else class="text-center mt-2">
          <h4>لا يوجد رسائل في البريد</h4>
        </div>
      </div>
    </b-form-checkbox-group>
  </ValidationObserver>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapState({
      contacts: ({ contactUs }) => contactUs.contacts,
    }),
    ...mapGetters(["contactList"]),
  },
  data: () => ({
    contactListData: [],
    selectedIds: [],
    allSelected: false,
  }),
  methods: {
    ...mapActions(["getContactsList", "deleteContacts"]),
    formDate(date) {
      let dete = `${new Date(date).toLocaleDateString()}`;
      return dete;
    },
    goDetails(id) {
      this.$router.push(`/admin/contactUs/${id}`);
    },
        // selectAll() {
        //   if (this.allSelected) {
        //     const selectedIds = this.contacts.map((u) => u.id);
        //     this.selectedIds = selectedIds;
        //   } else {
        //     this.selectedIds = [];
        //   }
        // },
        selectAll() {
  this.allSelected = !this.allSelected; // Toggle allSelected first
  if (this.allSelected) {
    this.selectedIds = this.contacts.map((u) => u.id);
  } else {
    this.selectedIds = [];
  }
},


    deleteCon() {
      this.deleteContacts(this.selectedIds);
    },
  },
  created() {
    this.getContactsList();
  },

};
</script>

<style scoped>
.card {
  border-radius: 0px;
}
.card .card-header {
  padding: 0.5rem !important;
}
</style>